.btn-xs {
	padding: 0 .375rem;
	font-size: .750rem;
	line-height: 1.5rem;
}

.btn-rounded {
	border-radius: 50rem;
}

.btn.disabled {
	cursor: not-allowed;
}

.form-control[readonly] {
	background-color: #fff;
	cursor: pointer;
}

/* Table */
.table>thead>tr>th,
.table>tbody>tr>td {
	vertical-align: middle;
}

/* Badge */

.custom-badge {
	font-size: 1.2em;
	padding-top: .25em;
	padding-bottom: .25em;
}


/* Card */

.card {
	border: 1px solid #c8ced3;
	border-radius: .1rem !important;
}

.card-header {
	background-color: #fff!important;
	padding: 15px;
	font-weight: 600;
	color: #73879c !important;
	font-size: .8rem;
	letter-spacing: .7px;
	text-transform: uppercase;
	border-radius: .1rem !important;
	border-top: 3px solid $app_main_color;
	border-bottom: 0;
	padding-bottom: 0;
}

.card-header:after {
	content: '';
	border-bottom: 1px solid rgba(0, 0, 0, .125);
	width: 100%;
	bottom: 0;
	left: 0;
	display: block;
	padding-top: .75rem;
}


/* Modal */

.modal-content {
	border-radius: 2px;
}

.modal-header {
	background-color: #dbe7f3;
	border-radius: 0;
}

.modal-header .close {
	color: #5a738e;
	font-weight: lighter;
	opacity: 1;
	text-shadow: none;
}

.modal-header .close:hover,
.modal-header .close:focus {
	opacity: .7;
}

.modal-title {
	color: #5a738e !important;
	font-size: 0.95rem;
	font-weight: 600;
	letter-spacing: 0.7px;
	text-transform: uppercase;
}

.modal-footer {
	background-color: #f6f7f8;
}


/* Alert */

.alert {
	border-radius: 0;
}

.alert-danger {
	border-left: 3px solid var(--danger);
}

.alert-dark {
	border-left: 3px solid var(--dark);
}

.alert-info {
	border-left: 3px solid var(--info);
}

.alert-light {
	border-left: 3px solid var(--light);
}

.alert-primary {
	border-left: 3px solid var(--primary);
}

.alert-success {
	border-left: 3px solid var(--success);
}

.alert-warning {
	border-left: 3px solid var(--warning);
}


/* Toastr */

.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-ok-btn {
	-ms-flex-order: 2;
	order: 2;
}

.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-cancel-btn {
	-ms-flex-order: 1;
	order: 1;
}

.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-ok-btn:active {
	background-color: var(--success) !important;
}

.rrt-confirm-holder .rrt-confirm .rrt-buttons-holder .rrt-button.rrt-cancel-btn:active {
	background-color: var(--danger) !important;
}

.redux-toastr .toastr .toastr-status.success {
	background-color: var(--success) !important;
}

.redux-toastr .toastr .toastr-status.warning {
	background-color: var(--warning) !important;
}

.redux-toastr .toastr .toastr-status.info {
	background-color: var(--info) !important;
}

.redux-toastr .toastr .toastr-status.error {
	background-color: var(--danger) !important;
}

.redux-toastr .toastr.rrt-info {
	background-color: var(--info) !important;
}

.redux-toastr .toastr.rrt-success {
	background-color: var(--success) !important;
}

.redux-toastr .toastr.rrt-warning {
	background-color: var(--warning) !important;
}

.redux-toastr .toastr.rrt-error {
	background-color: var(--danger) !important;
}

/* Captcha */

.g-recaptcha .grecaptcha-badge {
	z-index: 1000;
}

/* Select */
.react-select {
    .react-select{
        &__control{
            height: $input-height;
            min-height: auto;
            font-family: $input-font-family;
            @include font-size($input-font-size);
            font-weight: $input-font-weight;
            line-height: $input-line-height;
            color: $input-color;
            background-color: $input-bg;
            background-clip: padding-box;
            border: $input-border-width solid $input-border-color;
            border-color:$input-border-color;
            @include border-radius($input-border-radius, 0);
            @include transition($input-transition);
    
            &--menu-is-open ,&:hover, &:focus, &--is-focused{
                color: $input-focus-color;
                background-color: $input-focus-bg;
                border-color: $input-focus-border-color;
                outline: 0;
                // Avoid using mixin so we can pass custom focus shadow properly
                @if $enable-shadows {
                  box-shadow: $input-box-shadow, $input-focus-box-shadow;
                } @else {
                  box-shadow: $input-focus-box-shadow;
                }
            }
        }

        &__placeholder{
           color:  $input-placeholder-color
        }
        &__clear-indicator{
            color: $input-color;
        }

        &__option{
            background-color:  $input-bg;
            color: $input-color;
            &:hover, &--is-selected, &--is-focused{
                background: lighten($component-active-bg, 45%);
            }
            &--is-selected{
                background: lighten($component-active-bg, 40%);
            }
        }
    }
}