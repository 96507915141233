html,
body,
#root {
	font-size: .9rem;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

body {
	background-color: #f7f7f7;
	font-family: 'Didact Gothic', sans-serif;
}

:root {
	--app_main_color: #{$app_main_color};
}
