/* Header */

.app-header .nav-item>a>i:not(.text-danger) {
	color: #8D9DAF !important;
}

.app-header .nav-item>a:hover>i:not(.text-danger) {
	color: #5A738E !important;
}


/* Sidebar */

.sidebar .nav-item.open>.nav-link:not(:hover) {
	background-color: $sidebar-bg;
}