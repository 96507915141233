// If you want to override variables do it here
@import 'variables';

@import 'bootstrap';

// Font Awesome
@import '~@fortawesome/fontawesome-free/scss/fontawesome.scss';
@import '~@fortawesome/fontawesome-free/scss/solid.scss';
@import '~@fortawesome/fontawesome-free/scss/regular.scss';

@import '~react-redux-toastr/lib/css/react-redux-toastr.min.css';

@import 'coreui/styles';
@import 'coreui/custom-dropdown';
@import 'coreui/custom-sidebar';

@import 'main';
@import 'layout';
@import 'components';