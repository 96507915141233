// Variable overrides
// App
$app_main_color: #20a8d8;

// CoreUI 

// Sidebar
$sidebar-bg: #2A3F54;
$sidebar-nav-link-active-icon-color:$app_main_color;
$sidebar-nav-link-hover-bg: $app_main_color;
$sidebar-nav-link-active-bg: #3F5265;

// Font Awesome
$fa-font-display: swap;